import React from "react";
import Section from "../../components/Section/Section";
import "./Overview.scss";

const Overview = ({ client, description, industry, solutions, title }) => {
  return (
    <Section className="Overview">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="OverviewInfo">
              <h3>{title}</h3>
              <div
                className="OverviewText Body--small"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ClientInfo Body--small">
              <h5>Client</h5>
              <div>{client}</div>
              <h5>Industry</h5>
              <div>{industry}</div>
              <h5>Solutions</h5>
              <div>{solutions}</div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Overview;
